function getKeyValueObj(obj: URLSearchParams, keys: string[]) {
  return keys.reduce((acc, key) => ({ ...acc, [key]: obj.get(key) }), {});
}

/**
 * Parse the url
 * @param {string} [url=location.href] - default to currect url
 * @returns {{ hash: string, pathname: number, searchParams: URLSearchParams }}
 */
export const parseUrl = (
  url?: string
): Pick<URL, 'hash' | 'pathname' | 'searchParams'> => {
  const { hash, pathname, searchParams } = new URL(url ?? window.location.href);
  return {
    hash: hash.substring(1),
    pathname,
    searchParams
  };
};

/**
 * Parse the url's search parameters
 * @param {string | string[]} keys
 * @param {string} [search=location.href] - default to currect url's search
 * @returns {T}
 */
export const getUrlSearchParams = <T>(
  keys?: string | string[],
  search?: string
): T => {
  let returnValue: unknown;
  const params = new URLSearchParams(search ?? window.location.search);

  if (typeof keys === 'string') {
    returnValue = params.get(keys);
  } else if (Array.isArray(keys)) {
    returnValue = getKeyValueObj(params, keys);
  } else {
    returnValue = getKeyValueObj(params, Array.from(params.keys()));
  }

  return returnValue as T;
};
